@use './typography' as typography;


@mixin small-form() {
  .small-form {
    width: 320px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &--title {
      text-align: center;
      @each $key, $value in typography.$h4 {
        #{$key}: #{$value};
      }
    }
  }
}

@mixin small-form-host() {
  padding-top: 32px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
