@use "../typography" as typography;

.p-dialog .p-dialog-content {
  color: var(--g1);
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-style: var(--dialog-font-style, normal);
  font-weight: var(--dialog-font-weight, 700);
  line-height: var(--dialog-line-height, 24px);
  color: var(--g1);
  margin: 0px;
}

.p-dialog-content {
  font-family: var(--font-family);
  font-size: var(--dialog-font-style, 14px);
  font-weight: var(--dialog-font-weight, 400);
  line-height: var(--dialog-line-height, 20px);
  font-style: normal;
}

.p-dialog-header-icon timesicon {
  cursor: pointer;
}

.p-dialog .p-dialog-footer {
  padding: var(--dialog-padding, 16px);
}
